import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function formatRow(item) {
  const {id, user, code } = item;
  const { email, name } = user;
  return {
    name,
    email,
    code,
    id,
  };
}

const AdminReferralCodes = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/referral-codes',
        label: 'Referral Codes',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadReferralCodes = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/referral-codes${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setReferral Codes(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadReferralCodes();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'code',
      label: 'Code',
    },
    // {
    //   key: 'actions',
    //   label: '',
    //   isRight: true,
    //   render: (row) => (
    //     <Link
    //       to={`/app/admin/referral-codes/${row.id}`}
    //       className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //     >
    //       View
    //     </Link>
    //   ),
    // },
  ];


  const baseUrl = '/app/admin/referral-codes';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/referral-codes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'code',
        label: 'Code',
        type: 'text',
      },
    ],
    submitLabel: 'Search Referral Codes',
  };

  return (
    <AdminContainer>
      <SEO title="Referral codes" />
      <Heading title="Referral Codes" />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Referral Codes" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminReferralCodes;
